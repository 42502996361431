<template>
  <div
    class="modal fade"
    id="modal-form-placaTurno"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h4 class="modal-title text-white">Detalle</h4>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            ref="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 table-responsive p-0">
            <table
              class="table table-bordered table-striped table-hover table-sm"
              style="font-size: 11px"
            >
              <thead>
                <tr>
                  <th style="vertical-align: middle;" rowspan="2">
                    Turno
                  </th>
                  <th style="vertical-align: middle;" rowspan="2">
                    Placa
                  </th>
                  <th style="vertical-align: middle;" rowspan="2">
                    Remolque
                  </th>
                  <th
                    style="vertical-align: middle; text-align: center;"
                    rowspan="2"
                  >
                    Conductor
                  </th>
                  <th
                    style="vertical-align: middle; text-align: center;"
                    rowspan="2"
                  >
                    Fecha Inicio
                  </th>
                  <th
                    style="vertical-align: middle; text-align: center;"
                    rowspan="2"
                  >
                    Fecha Fin
                  </th>

                  <th class="text-center" colspan="3">Operativo</th>
                  <th
                    class="text-center"
                    colspan="3"
                    v-if="$parent.liquidacion.tipo_tarifa == 3"
                  >
                    Disponible
                  </th>
                  <th class="text-center" colspan="2">Indisponible</th>
                  <th
                    class="text-center"
                    colspan="2"
                    v-if="$parent.liquidacion.tipo_tarifa == 2"
                  >
                    Kilometros
                  </th>
                  <th class="text-center" colspan="3">Total</th>
                </tr>
                <tr>
                  <th>Horas</th>
                  <th>Fracción</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 2">
                    Costo.T.Fija
                  </th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 3">Valor</th>
                  <th>Horas</th>
                  <th>Fracción</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 2">Cantidad</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 3">Valor</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 2">
                    Costo.T.Variable
                  </th>
                  <th>Horas</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 3">Fracción</th>
                  <th v-if="$parent.liquidacion.tipo_tarifa == 3">Horas</th>
                  <th>Fracción</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in liqui" :key="item.id">
                  <td class="text-center">
                    {{ item.id }}
                  </td>
                  <td class="text-center">
                    {{ item.vehiculo.placa }} -
                    {{ item.vehiculo.tipo_vehiculo.nombre }}
                  </td>
                  <td class="text-center">
                    {{ item.remolque ? item.remolque.placa : "" }}
                  </td>
                  <td class="text-center">
                    {{ item.conductor.numero_documento }} -
                    {{ item.conductor.nombres }} {{ item.conductor.apellidos }}
                  </td>
                  <td class="text-center">
                    {{ item.fecha_ini }}
                  </td>
                  <td class="text-center">
                    {{ item.fecha_fin }}
                  </td>
                  <!-- Horas Operativa -->
                  <td style="vertical-align: middle; text-align: center;">
                    {{ (item.tiempo_operativo / 60).toFixed(2) }}
                  </td>
                  <!-- Fracción Operativa -->
                  <td style="vertical-align: middle; text-align: center;">
                    {{
                      (
                        item.tiempo_operativo /
                        60 /
                        item.turno.dia_turno_one.cant_horas
                      ).toFixed(2)
                    }}
                  </td>
                  <!-- Costo Fijo Operativa -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{ item.costoFijo }}
                  </td>
                  <!-- Valor Operativa -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{ item.valorOpera }}
                  </td>
                  <!-- Horas Disponible -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{ (item.tiempo_disponible / 60).toFixed(2) }}
                  </td>
                  <!-- Fracción Disponible -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{
                      (
                        item.tiempo_disponible /
                        60 /
                        item.turno.dia_turno_one.cant_horas
                      ).toFixed(2)
                    }}
                  </td>
                  <!-- Valor Disponible -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{ item.valorDispo }}
                  </td>
                  <!-- Horas Indisponible -->
                  <td style="vertical-align: middle; text-align: center;">
                    {{ (item.tiempo_indisponible / 60).toFixed(2) }}
                  </td>
                  <!-- Fracción Indisponible -->
                  <td style="vertical-align: middle; text-align: center;">
                    {{
                      (
                        item.tiempo_indisponible /
                        60 /
                        item.turno.dia_turno_one.cant_horas
                      ).toFixed(2)
                    }}
                  </td>
                  <!-- Cantifdad Km -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{ item.km_final ? item.km_final : 0 }}
                  </td>
                  <!-- Costo Variable Km -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{ item.costoVariable }}
                  </td>
                  <!-- Horas Total -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{
                      (
                        item.tiempo_operativo / 60 +
                        item.tiempo_indisponible / 60
                      ).toFixed(2)
                    }}
                  </td>
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{
                      (
                        item.tiempo_operativo / 60 +
                        item.tiempo_disponible / 60 +
                        item.tiempo_indisponible / 60
                      ).toFixed(2)
                    }}
                  </td>
                  <!-- Fracción Total -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{
                      (
                        item.tiempo_operativo /
                          60 /
                          item.turno.dia_turno_one.cant_horas +
                        item.tiempo_indisponible /
                          60 /
                          item.turno.dia_turno_one.cant_horas
                      ).toFixed(2)
                    }}
                  </td>
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{
                      (
                        item.tiempo_operativo /
                          60 /
                          item.turno.dia_turno_one.cant_horas +
                        item.tiempo_indisponible /
                          60 /
                          item.turno.dia_turno_one.cant_horas +
                        item.tiempo_disponible /
                          60 /
                          item.turno.dia_turno_one.cant_horas
                      ).toFixed(2)
                    }}
                  </td>
                  <!-- Valor Total -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 2"
                  >
                    {{ item.valorTotalF }}
                  </td>
                  <!-- Valor Total -->
                  <td
                    style="vertical-align: middle; text-align: center;"
                    v-if="item.tipo_tarifa == 3"
                  >
                    {{ item.valorTotal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TifLiquidacionesDetPlaca",
  components: {},

  data() {
    return {
      liqui_id: null,
      vehiculo_id: null,
      turno_id: null,
      tipo_vehiculo_id: null,
      liqui: {},
      listasForms: {},
    };
  },
  validations: {
    form: {},
  },

  methods: {
    async llenar_modal_detPlaca(
      liqui,
      vehiculo_id,
      turno_id,
      tipo_vehiculo_id
    ) {
      await this.getDatosPlaca(liqui, vehiculo_id, turno_id, tipo_vehiculo_id);
    },

    async getDatosPlaca(liqui, vehiculo_id, turno_id, tipo_vehiculo_id) {
      this.liqui_id = liqui;
      this.vehiculo_id = vehiculo_id;
      this.turno_id = turno_id;
      this.tipo_vehiculo_id = tipo_vehiculo_id;
      this.liqui = {};
      await axios
        .get("/api/tif/detLiquidaciones/detPlaca", {
          params: {
            liqui_id: liqui,
            vehiculo_id: vehiculo_id,
            turno_id: turno_id,
            tipo_vehiculo_id: tipo_vehiculo_id,
          },
        })
        .then((response) => {
          this.liqui = response.data;
          for (let i = 0; i < this.liqui.length; i++) {
            let costoFijo =
              (this.liqui[i].det_tarifa.costo_fijo *
                this.liqui[i].tiempo_operativo) /
              60 /
              this.liqui[i].turno.dia_turno_one.cant_horas;

            this.liqui[i].costoFijo = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoFijo
            );

            let costoVariable =
              this.liqui[i].det_tarifa.costo_variable * this.liqui[i].km_final;

            this.liqui[i].costoVariable = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoVariable
            );

            let valorTotalF = costoVariable + costoFijo;

            this.liqui[i].valorTotalF = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorTotalF
            );

            let valorOpera =
              (this.liqui[i].det_tarifa.valor_op *
                this.liqui[i].tiempo_operativo) /
              60 /
              this.liqui[i].turno.dia_turno_one.cant_horas;

            this.liqui[i].valorOpera = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorOpera
            );

            let valorDispo =
              (this.liqui[i].det_tarifa.valor_disp *
                this.liqui[i].tiempo_disponible) /
              60 /
              this.liqui[i].turno.dia_turno_one.cant_horas;

            this.liqui[i].valorDispo = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorDispo
            );

            let valorTotal = valorOpera + valorDispo;

            this.liqui[i].valorTotal = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorTotal
            );
          }
        });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    /* limpiarModal() {
      this.turno = {};
      this.turno_id = null;
    }, */
  },

  mounted() {},
};
</script>
